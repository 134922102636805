import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { KeycloakProfile } from 'keycloak-js';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  emailMapping: { [key: string]: string } = {};
  private _user: KeycloakProfile;

  constructor(
    private readonly _authService: AuthService
  ) {}

  set user(user: KeycloakProfile) {
    this._user = user;
  }

  get user() {
    return this._user;
  }

  getUniqueIdFromUserEmailAndTimestamp() {
    let uid = undefined;
    try {
      const userProfile = this._authService.userProfile;
      const email = userProfile.info.email;
      const uidEmail = this.emailToUID(email);
      const timestamp = Date.now();
      uid = uidEmail + timestamp;

      return uid;
    } catch (error) {
      throw new Error('Email info missing');
    }
  }

  getEmailAndTimestampFromUID(uid: string) {
    const lengthTimestampDigits = 13;
    const timestamp = uid.slice(-lengthTimestampDigits);
    const uidEmail = uid.replace(timestamp, '');
    const email = this.uidToEmail(uidEmail);

    return [email, timestamp];
  }

  emailToUID(email: string): string {
    return Buffer.from(email).toString("base64").replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
  }
  
  uidToEmail(uid: string): string | null {
    const base64 = uid.replace(/-/g, '+').replace(/_/g, '/');
    const padding = '='.repeat((4 - (base64.length % 4)) % 4);
    return Buffer.from(base64 + padding, 'base64').toString('utf8');  
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { RadioItem } from './radio-item.interface';

@Component({
    selector: 'mcp-radio-item-list',
    templateUrl: 'radio-item-list.component.html',
    styleUrls: ['radio-item-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class RadioItemListComponent implements OnChanges {
  @Input() groupId: string = 'group';
  @Input() items: RadioItem[] = [];
  @Output() changeEvent: EventEmitter<RadioItem> =
    new EventEmitter<RadioItem>();

  selectedId = '';
  isDisabled = false;

  ngOnChanges() {
    this.items.forEach((item) => {
      if (item.checked) {
        this.selectedId = item.id;
      }
    });
  }

  onChange(item: RadioItem) {
    console.log('selected: ', item);
    this.updateCheckedState();
    this.changeEvent.emit(item);
  }

  updateCheckedState() {
    this.items.forEach((item) => {
      this.selectedId === item.id
        ? (item.checked = true)
        : (item.checked = false);
    });
  }
}

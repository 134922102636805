import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { UserService } from '@app/shared/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class KCAuthGuard {
  authenticated = false;

  constructor(
    protected readonly router: Router,
    protected readonly _userService: UserService,
    protected readonly _authService: AuthService
  ) {
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.authenticated = this._authService.hasValidAccessToken;
    console.log('[KCAuthGuard] isAccessAllowed - authenticated: ', this.authenticated);
    if (!this.authenticated) {
      try {
        await this._authService.login();
      } catch (error) {
        console.log('[KcAuthGuard] isAccessAllowed - _authService.login - error: ', error);
      }
    }

    return this.authenticated;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('KcAuthGuard - canActivate - state: ', state);
    return this.isAccessAllowed(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }

  async logout() {
    try {
      this._authService.logout();
      console.log('[KCAuthGuard] logout - success');
    } catch (error) {
      console.log('[KCAuthGuard] logout - error: ', error);
    }
  }
}

import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ROUTES } from '@app/app-routing.config';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { BuildTarget } from '../enums/enums';

@Injectable({
  providedIn: 'root'
})
export abstract class AuthService {
  constructor(
    private readonly _oauthService: OAuthService,
    private readonly zone: NgZone,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute
  ) {

    if (environment.buildTarget === BuildTarget.NATIVE) {
      this._configureIOS();
    } else {
      this._configureWeb();
    }
  }

  public userProfile: any;
  public hasValidAccessToken = false;
  public realmRoles: string[] = [];

  init() {
    this._oauthService.loadDiscoveryDocument()
      .then(loadDiscoveryDocumentResult => {
        console.log("loadDiscoveryDocument", loadDiscoveryDocumentResult);

        /**
         * Do we have a valid access token? -> User does not need to log in
         */
        this.hasValidAccessToken = this._oauthService.hasValidAccessToken();

        if (!this.hasValidAccessToken) {
          /**
           * Always call tryLogin after the app and discovery document loaded, because we could come back from Keycloak login page.
           * The library needs this as a trigger to parse the query parameters we got from Keycloak.
           */
          this._oauthService.tryLogin().then(tryLoginResult => {
            this.hasValidAccessToken = this._oauthService.hasValidAccessToken();
            console.log('[AuthService] onInit - oauthService.tryLogin - result/hasValidAccessToken', tryLoginResult, this.hasValidAccessToken);
            if (this.hasValidAccessToken){
              this._loadUserProfile();
              //this.realmRoles = this.getRealmRoles();
              this._router.navigate([ROUTES.DEVICE_PREP]);
            }
          });
        } else {
          this._loadUserProfile();
        }
      })
      .catch(error => {
        console.error("loadDiscoveryDocument", error);
      });

    this._oauthService.events.subscribe(eventResult => {
      console.debug("LibEvent", eventResult);
      this.hasValidAccessToken = this._oauthService.hasValidAccessToken();
    });

    if (environment.buildTarget === BuildTarget.NATIVE) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        console.log('Access Token:', this._oauthService.getAccessToken());
        console.log('ID Token:', this._oauthService.getIdToken());
        let url = new URL(event.url);
        if(url.host != "login"){
          return;
        }

        this.zone.run(() => {

          // Building a query param object for Angular Router
          const queryParams: Params = {};
          for (const [key, value] of url.searchParams.entries()) {
            queryParams[key] = value;
          }
  
          // Add query params to current route
          this._router.navigate(
            [],
            {
              relativeTo: this._activatedRoute,
              queryParams: queryParams,
              queryParamsHandling: 'merge', // remove to replace all query params by provided
            })
            .then(navigateResult => {
              // After updating the route, trigger login in oauthlib and
              this._oauthService.tryLogin().then(tryLoginResult => {
                console.log("tryLogin", tryLoginResult);
                if (this.hasValidAccessToken){
                  this._loadUserProfile();
                  this.realmRoles = this.getRealmRoles();
                }
              })
            })
            .catch(error => console.error(error));
        });
      });
    }
  }

  getToken() {
    return this._oauthService.getAccessToken();
  }

  public async login(): Promise<boolean> {
    try {
      const loadDiscoveryDocumentAndLoginResult = await this._oauthService.loadDiscoveryDocumentAndLogin();
      console.log("loadDiscoveryDocumentAndLogin", loadDiscoveryDocumentAndLoginResult);
      return loadDiscoveryDocumentAndLoginResult;
    } catch (error) {
      console.error("loadDiscoveryDocumentAndLogin", error);
      return false;
    }
  }

  public logout(): void {
    this._oauthService.revokeTokenAndLogout()
      .then(revokeTokenAndLogoutResult => {
        console.log("revokeTokenAndLogout", revokeTokenAndLogoutResult);
        this.userProfile = null;
        this.realmRoles = [];
      })
      .catch(error => {
        console.error("revokeTokenAndLogout", error);
      });
  }

  /**
   *  Use this method only when an id token is available.
   *  This requires a specific mapper setup in Keycloak. (See README file)
   *
   *  Parses realm roles from identity claims.
   */
  public getRealmRoles(): string[] {
    let idClaims = this._oauthService.getIdentityClaims()
    if (!idClaims){
      console.error("Couldn't get identity claims, make sure the user is signed in.")
      return [];
    }
    if (!idClaims.hasOwnProperty("realm_roles")){
      console.error("Keycloak didn't provide realm_roles in the token.")
      return [];
    }

    let realmRoles = idClaims["realm_roles"]
    return realmRoles ?? [];
  }

  private async _loadUserProfile() {
    try {
      this.userProfile = await this._oauthService.loadUserProfile();
    } catch (error) {
      console.error('[AuthService] _loadUserProfile - error: ', error);
    }
  }

  private _configureWeb(): void {
    console.log("Using web configuration")
    let authConfig: AuthConfig = {
      issuer: environment.keycloak.issuer + 'realms/' + environment.keycloak.realm,
      redirectUri: window.location.origin,
      clientId: environment.keycloak.clientId,
      responseType: 'code',
      scope: 'openid profile email',
      // Revocation Endpoint must be set manually when using Keycloak
      // See: https://github.com/manfredsteyer/angular-oauth2-oidc/issues/794
      revocationEndpoint: environment.keycloak.issuer + 'realms/' + environment.keycloak.realm + '/protocol/openid-connect/revoke',
      showDebugInformation: true,
      requireHttps: false
    }
    this._oauthService.configure(authConfig);
    this._oauthService.setupAutomaticSilentRefresh();
  }

  private _configureIOS(): void {
    console.log("Using iOS configuration")
    let authConfig: AuthConfig = {
      issuer: environment.keycloak.issuer + 'realms/' + environment.keycloak.realm,
      redirectUri: "mcp://login",
      clientId: environment.keycloak.clientId,
      responseType: 'code',
      scope: 'openid profile email',
      strictDiscoveryDocumentValidation: false,
      // Revocation Endpoint must be set manually when using Keycloak
      // See: https://github.com/manfredsteyer/angular-oauth2-oidc/issues/794
      revocationEndpoint: environment.keycloak.issuer + 'realms/' + environment.keycloak.realm + '/protocol/openid-connect/revoke',
      showDebugInformation: true,
      requireHttps: false,
      disablePKCE: false,
      useSilentRefresh: false,
      sessionChecksEnabled: true
    }
    this._oauthService.configure(authConfig);
    this._oauthService.setupAutomaticSilentRefresh();
  }
}

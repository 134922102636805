export function getDeepCopy(object: any) {
  return JSON.parse(JSON.stringify(object));
}

export function createArrayNumbersFromTo(from: number, to: number, step: number = 1) {
  const returnValue: number[] = [];
  if (from < to) {
    for (let i = from; i < to; i+=step) {
      returnValue.push(i);
    }
  } else {
    for (let i = from; i > to; i-=step) {
      returnValue.push(i);
    }
  }

  return returnValue;
}

export function createRandomUUID() {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function createIdFromNumbersWithLength(length: number) {
  let result = '';
  const characters = '0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function createIdFromCharsWithLength(length: number) {
  let result = '';
  const characters = 'abcdefghijklmnopqerstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function secondsToDhm(seconds: number): string {
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  return zeroPad(d, 3) + ':' + zeroPad(h, 2) + ':' + zeroPad(m, 2);
}

export function formatArrayForDisplay(arr: any[]) {
  return Array.isArray(arr) ? arr.join(' | ') : arr;
}

export function toCamelCase(str: string): string {
  return str
    .toLowerCase()
    .replace(/_([a-z])/gi, (_, letter) => letter.toUpperCase())
    .replace(/^([a-z])/, (_, letter) => letter.toLowerCase());
}

export function toAllCaps(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, "$1_$2")
    .replace(/([A-Z]+)([A-Z][a-z])/g, "$1_$2")
    .toUpperCase();
}

export function convertToDataView(uint8Array: Uint8Array): DataView {
  return new DataView(uint8Array.buffer, uint8Array.byteOffset, uint8Array.byteLength);
}

const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0');

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractMcpDeviceService } from '@core/services';
import { ButtonType } from '@shared/components/button/utils';
import { PositionTypeEnum } from '@core/enums';
import { DoorMovement, DoorState } from '@app/shared/enums/enums';
import { RemoteControlService, RemoteControlStatesKV } from './remote-control.service';
import { Subscription } from 'rxjs';
import { CommId } from '@app/core/services/broadcast.service';

@Component({
    selector: 'mcp-remote-control',
    templateUrl: 'remote-control.component.html',
    styleUrls: ['remote-control.component.scss'],
    standalone: false
})
export class RemoteControlComponent implements OnInit, OnDestroy {
  buttonType = ButtonType.Success;
  positionType = PositionTypeEnum;
  @Input() hasOpenButton = false;
  @Input() hasIntermediateOpenButton = false;
  @Input() hasIntermediateCloseButton = false;
  @Input() hasDeactivationPointOpenButton = false;
  @Input() hasDeactivationPointCloseButton = false;
  @Input() hasLSPointButton = false;
  @Input() hasSafetyEdgeTestPointButton = false;
  @Input() hasCloseButton = false;
  @Input() hasStopButton = false;
  @Input() description: string;
  intermediateOpenedDisabled = false;
  intermediateClosedDisabled = false;
  doorState = DoorState.INTERMEDIATE_CLOSED;
  subscriptions: Subscription[] = [];
  CommId = CommId;
  buttonStatesKV: RemoteControlStatesKV;

  constructor(
    private readonly _mcpDevice: AbstractMcpDeviceService,
    private remoteControlService: RemoteControlService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.remoteControlService.updateOnDoorPosition.subscribe(state => {
        this.doorState = state;
      })
    );

    this.subscriptions.push(
      this.remoteControlService.updateOnRemoteControlStates.subscribe(states => {
        this._updateButtonStates(states);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item) => item.unsubscribe());
  }

  moveDoorClickHandler(positionType: PositionTypeEnum) {
    switch (positionType) {
      case PositionTypeEnum.epo:
        this.writeDoorMovement(DoorMovement.END_POSITION_OPEN_INSIDE);
        break;
      case PositionTypeEnum.epc:
        this.writeDoorMovement(DoorMovement.END_POSITION_CLOSE);
        break;
      case PositionTypeEnum.ipo:
        this.writeDoorMovement(DoorMovement.IME_POSITION_OPEN);
        break;
      case PositionTypeEnum.ipc:
        this.writeDoorMovement(DoorMovement.IME_POSITION_CLOSE);
        break;
      case PositionTypeEnum.deactivationPointOpen:
        this.writeDoorMovement(DoorMovement.IME_SP_DEACTIVATION_POINT_OPEN);
        break;
      case PositionTypeEnum.deactivationPointClose:
        this.writeDoorMovement(DoorMovement.IME_SP_DEACTIVATION_POINT_CLOSE);
        break;
      case PositionTypeEnum.lsPoint:
        this.writeDoorMovement(DoorMovement.IME_SP_LS_POINT);
        break;
      case PositionTypeEnum.safetyEdgeTestPoint:
        this.writeDoorMovement(DoorMovement.IME_SP_SAFETY_EDGE_TEST_POINT);
        break;
    }
  }

  writeDoorMovement(doorMovement: DoorMovement) {
    const movementIndex = doorMovement as number;
    this.writeData(CommId.ID_IMPULS_TARGET, movementIndex);
  }

  stopClickHandler() {
    this.writeDoorMovement(DoorMovement.POSITION_STOP);
  }

  writeData(id: number, content: number | number[]) {
    this._mcpDevice.clbk_writetoid(id, content);
  }

  _updateButtonStates(states: RemoteControlStatesKV) {
    this.buttonStatesKV = states;
    const ime = states[CommId.ID_INTERMEDIATE_POSITIONS] as number[];
    this.intermediateOpenedDisabled = ime[0] === 0 ? true : false;
    this.intermediateClosedDisabled = ime[1] === 0 ? true : false;
  }
}

import { query } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal
} from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
    selector: 'mcp-searchfield',
    templateUrl: 'searchfield.component.html',
    styleUrls: ['searchfield.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class SearchFieldComponent implements OnInit {
  @Input() description = '';
  @Input() text = signal('');
  @Output() changeEvent: EventEmitter<string | null> = new EventEmitter<string | null>();

  isDisabled = false;
  searchTerm = new FormControl<string>('');

  constructor() {
    effect(() => {
      console.log('Signal changed SearchField:', this.text());
    });
  }

  ngOnInit() {
    console.log('this.text: ', this.text());
    // if (this.text) {
    //   console.log('NAME: ', this.text.name);
    //   this.searchTerm.setValue(this.text.name);
    // }
    
  }

  search(evt: Event) {
    console.log('query: ', query);
    const value = (evt.target as HTMLInputElement).value;
    this.text.set(value);
        this.changeEvent.emit(value);
    // this.searchTerm.valueChanges
    //   .pipe(debounceTime(500), distinctUntilChanged())
    //   .subscribe(query => {
    //     console.log('query: ', query);
    //     this.changeEvent.emit(query);
    //   });
  }
}

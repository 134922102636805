/* eslint-disable @typescript-eslint/naming-convention */
import { ButtonEventEnum, ButtonTypeEnum, PositionTypeEnum } from '@core/enums';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  delay,
  interval,
  of,
  take,
  tap
} from 'rxjs';

import { AbstractMcpDeviceService } from './abstract-mcp-device.service';
import { DeviceInfoModel } from '../models';
import { Injectable } from '@angular/core';
import { MaxPositionValue } from '@app/features/main/features/installation/components/fine-tuning-position-state-change/consts/max-position-value.const';
import { BroadcastRegister, DeviceResponse } from '@app/shared/interfaces/interfaces';
import { BroadcastCategory } from '@app/shared/enums/enums';

@Injectable()
export class McpDeviceMockService extends AbstractMcpDeviceService {
  bluetoothConnection$ = interval(2000);
  subscription: Subscription;
  areAllinitialBroadcastsRegistered = false;

  clbk_connect(): void {
    setTimeout(() => {
      this._initMockInfo();
      this.setConnected(true);
    }, 200);
  }

  clbk_disconnect(): void {
    setTimeout(() => {
      this.setConnected(false);
    }, 1000);
  }

  // Start Bluetooth fake connection
  // clbk_start_informations_reading(): Observable<any> {
  //   console.log('STARTING connection to retrieve device info');
  //   this.subscription = this.bluetoothConnection$.subscribe(() =>
  //     this._initMockInfo()
  //   );
  //   return new Observable((observer) => {
  //     this.getDeviceInfo().subscribe((deviceInfo: DeviceInfoModel) =>
  //       observer.next(deviceInfo)
  //     );
  //   });
  // }

  // clbk_stop_informations_reading(): void {
  //   console.log('STOPPING connection to retrieve device info');
  //   this.subscription.unsubscribe();
  // }

  // clbk_upload_informations_reading(): void {
  //   this.setIsUploading(true);
  //   setTimeout(() => {
  //     this.setIsFinished(true);
  //     this.setIsUploading(false);
  //   }, 3000);
  // }

  getBroadcastById(id: number) {
    const broadcast: BroadcastRegister = {
      id: 1
    }
    return broadcast;
  };

  clbk_epo_save(): Observable<void> {
    this._log(
      'clbk_epo_save function called. Sending end position of opened state to device'
    );

    return of(undefined);
  }

  clbk_epo_move(
    buttonType: ButtonTypeEnum,
    buttonEvent: ButtonEventEnum,
    reverseDirection: boolean
  ): void {
    this._log(
      'clbk_epo_move function called. Sending end position of opened state to device with parameters',
      buttonType,
      buttonEvent,
      reverseDirection
    );
  }

  clbk_epc_move(
    buttonType: ButtonTypeEnum,
    buttonEvent: ButtonEventEnum,
    reverseDirection: boolean
  ): void {
    this._log(
      'clbk_epc_move function called. Sending end position of closed state to device with parameters',
      buttonType,
      buttonEvent,
      reverseDirection
    );
  }

  clbk_ips_move(
    buttonType: ButtonTypeEnum,
    buttonEvent: ButtonEventEnum
  ): void {
    this._log(
      'clbk_ipc_move function called. Sending intermediate position to device with parameters',
      buttonType,
      buttonEvent
    );
  }

  clbk_fto_save(ftp_epo: number): Observable<void> {
    this._log('clbk_fto_save function called. Parameter value:', ftp_epo);
    return of(undefined);
  }

  clbk_ftc_save(ftp_epc: number): Observable<void> {
    this._log('clbk_ftc_save function called. Parameter value:', ftp_epc);
    return of(undefined);
  }

  clbk_ispositionsaved(positiontype: PositionTypeEnum): Observable<boolean> {
    this._log(
      'clbk_ispositionsaved function called. Parameter value:',
      positiontype
    );
    return of(
      this._getValueFromStorage(`mock.ispositionsaved.${positiontype}`)
    );
  }

  clbk_approach_epo(): void {
    this._log('clbk_approach_epo function called');
    setTimeout(() => {
      this.clbk_epoready();
    }, 2000);
  }

  clbk_approach_epc(): void {
    this._log('clbk_approach_epc function called');
    setTimeout(() => {
      this.clbk_epcready();
    }, 2000);
  }

  clbk_approach_ipo(): void {
    this._log('clbk_approach_ipo function called');
    setTimeout(() => {
      this.clbk_ipoready();
    }, 2000);
  }

  clbk_approach_ipc(): void {
    this._log('clbk_approach_ipc function called');
    setTimeout(() => {
      this.clbk_ipcready();
    }, 2000);
  }

  clbk_getswitchposition(): Observable<boolean> {
    this._log('clbk_getswitchposition function called');

    return this.getIsDoorDirectionReversed$();
  }

  clbk_ip_deleteintpos(intposopen: boolean): Observable<void> {
    this._log(
      'clbk_ip_deleteintpos function called with parameter',
      intposopen
    );
    localStorage.removeItem(
      `mock.ispositionsaved.${
        intposopen ? PositionTypeEnum.ipo : PositionTypeEnum.ipc
      }`
    );

    return of(undefined);
  }

  clbk_ipo_save(): Observable<void> {
    this._log('clbk_ipo_save function called');
    localStorage.setItem(
      `mock.ispositionsaved.${PositionTypeEnum.ipo}`,
      true.toString()
    );

    return of(undefined);
  }

  clbk_ipc_save(): Observable<void> {
    this._log('clbk_ipc_save function called');
    localStorage.setItem(
      `mock.ispositionsaved.${PositionTypeEnum.ipc}`,
      true.toString()
    );

    return of(undefined);
  }

  registerBroadcastsByCategory(category: BroadcastCategory): Promise<void> { return Promise.resolve() }
  unregisterBroadcastsByCategory(category: BroadcastCategory): Promise<void> { return Promise.resolve() }

  updateOnId: BehaviorSubject<DeviceResponse | null> =
    new BehaviorSubject<DeviceResponse | null>(null);

  updateOnDisconnect: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(null);

  registerIds(ids: number[]) {
    return Promise.resolve({});
  }

  unregisterIds(ids: number[]) {
    return Promise.resolve({});
  }

  clbk_getfromid(id: number): Observable<any[]> {
    return of([]);
  }

  clbk_writetoid(id: number, value: number | number[]): Observable<any[]> {
    return of([]);
  }

  clbk_getlearningstateendpos(): Observable<boolean> {
    this._log('clbk_getlearningstateendpos function called');

    return of(this._getRandomizedBooleanValue());
  }

  clbk_getpreconditionsstatefinetuning(): Observable<boolean> {
    this._log('clbk_getpreconditionsstatefinetuning function called');

    return of(this._getRandomizedBooleanValue());
  }

  /*clbk_savedoordirectionswitchposition(
    reverseDoorDirection: boolean
  ): Observable<void> {

    return of(this._getRandomizedBooleanValue()).pipe(
      tap((wasTheExecutionSuccessful: boolean) => {
        if (wasTheExecutionSuccessful) {
          this.setIsDoorDirectionReversed(reverseDoorDirection);
          this._log(
            `clbk_savedoordirectionswitchposition function called. The execution was successful, SWT_EPOS_ReverseDoorDirection::value ${reverseDoorDirection
              .toString()
              .toUpperCase()} has been set.`
          );

          return;
        }

        setTimeout(() =>
          this.setIsDoorDirectionReversed(!reverseDoorDirection)
        );
        this._log(
          'clbk_savedoordirectionswitchposition function called. The execution failed.'
        );
      })
    );


    this.setIsDoorDirectionReversed(reverseDoorDirection);
    this._log(
      `clbk_savedoordirectionswitchposition function called. The execution was successful, SWT_EPOS_ReverseDoorDirection::value ${reverseDoorDirection
        .toString()
        .toUpperCase()} has been set.`
    );
    return of(undefined);
  }*/

  f_ep_setup_abort(): void {
    this._log('f_ep_setup_abort function called');
  }

  f_epo_change_abort(): void {
    this._log('f_epo_change_abort function called');
  }

  f_epc_change_abort(): void {
    this._log('f_epc_change_abort function called');
  }

  clbk_initiateendpossetup(): Observable<void> {
    this._log('clbk_initiateendpossetup function called');
    this._resetReverseDoorDirectionValue();

    return of(undefined);
  }

  clbk_initiateendposopenedchange(): Observable<void> {
    this._log('clbk_initiateendposopenedchange function called');

    return of(undefined);
  }

  clbk_initiateendposclosedchange(): Observable<void> {
    this._log('clbk_initiateendposclosedchange function called');

    return of(undefined);
  }

  clbk_IP_initiatelrn(): Observable<void> {
    this._log('clbk_IP_initiatelrn function called');

    return of(undefined);
  }

  clbk_IP_abortlrn(): void {
    this._log('clbk_IP_abortlrn function called');
  }

  clbk_epo_save_and_calibrate(): Observable<void> {
    this._log('clbk_epo_save_and_calibrate function called');

    return of(undefined).pipe(
      delay(5000),
      tap(() =>
        this._logTheResultOfTheCalibrationProcessToTheConsole(true, 'opened')
      )
    );
  }

  clbk_epc_save_and_calibrate(): Observable<void> {
    this._log('clbk_epc_save_and_calibrate function called');

    return of(undefined).pipe(
      delay(5000),
      tap(() =>
        this._logTheResultOfTheCalibrationProcessToTheConsole(true, 'closed')
      ),
      tap(this._saveReverseDoorDirectionValueInLocalStorage.bind(this))
    );
  }

  clbk_epc_save(): Observable<void> {
    this._log('clbk_epc_save function called');
    return of(undefined);
  }

  mcp_stop_movement(): void {
    this._log('mcp_stop_movement function called');
  }

  clbk_fto_epo_approach(): void {
    this._log('clbk_fto_epo_approach function called');
    this._mockMovementOfDoor();
  }

  clbk_fto_epc_approach(): void {
    this._log('clbk_fto_epc_approach function called');
    this._mockMovementOfDoor();
  }

  clbk_ftc_epo_approach(): void {
    this._log('clbk_ftc_epo_approach function called');
    this._mockMovementOfDoor();
  }

  clbk_ftc_epc_approach(): void {
    this._log('clbk_ftc_epc_approach function called');
    this._mockMovementOfDoor();
  }

  clbk_get_ftp_epo(): Observable<number> {
    this._log('clbk_get_ftp_epo function called');

    return of(this._getInitialPositionValue());
  }

  clbk_get_ftp_epc(): Observable<number> {
    this._log('clbk_get_ftp_epc function called');

    return of(this._getInitialPositionValue());
  }

  setInitialBroadcastItems(): void {};
  registerBroadcasts(): void {};

  private _initMockInfo(): void {
    // this.hardwareVersion$.next('2.15.13');
    // this.softwareVersionSC$.next('1.0.7');
    // this.softwareVersionCC$.next('1.0.6');
    // this.serialNumber$.next('123456.ddyy.123123');
    // this.deviceId$.next('aw38raw3f8awha3');
    // this.deviceName$.next('deviceName');
    // this.doorCycleCounterTotal$.next(this._getRandomNumber());
    // this.powerOnHours$.next(this._getRandomNumber());
    // this.doorRunTimerTotal$.next(this._getRandomNumber());
    // this.currentDoorPosition$.next(this._getRandomNumber());
    // this.currentAwgValue$.next(this._getRandomNumber());
    // this.awgSerial$.next('1');
    // this.awgRevision$.next('2');
    // this.displaySerial$.next('1443');
    // this.displayRevision$.next('2');
    // this.doorLearnState$.next('Unlearned');
  }

  private _getRandomNumber(multiplierForPseudoRandomNumber = 1000): string {
    return `${Math.floor(Math.random() * multiplierForPseudoRandomNumber)}`;
  }

  private _getInitialPositionValue(): number {
    const randomizedNumberValue =
      parseInt(this._getRandomNumber(MaxPositionValue)) + 1;
    const shouldBeNegativeNumber = this._getRandomizedBooleanValue();
    const result = shouldBeNegativeNumber
      ? -randomizedNumberValue
      : randomizedNumberValue;

    return result;
  }

  private _getValueFromStorage(key: string): boolean {
    const item = localStorage.getItem(key);
    if (!item) {
      return false;
    }
    return item === 'true';
  }

  private _getRandomizedBooleanValue(): boolean {
    const availableValues: [true, false] = [true, false];
    const randomizedValue =
      availableValues[Math.floor(Math.random() * availableValues.length)];

    return randomizedValue;
  }

  private _resetReverseDoorDirectionValue(): void {
    const valueToSet = this._getValueFromStorage('mock.getswitchposition');
    this.setIsDoorDirectionReversed(valueToSet);
  }

  private _log(message: string, ...params: any[]): void {
    const color = 'orange';
    console.log(`%c${message}`, `color: ${color}`, ...params);
  }

  private _logTheResultOfTheCalibrationProcessToTheConsole(
    value: boolean,
    state: 'opened' | 'closed'
  ): void {
    this._log(
      `The automated calibration trip of the gate ${
        value ? 'was SUCCESSFUL' : 'FAILED'
      }. Sending end position of ${state} state to device`
    );
  }

  private _saveReverseDoorDirectionValueInLocalStorage(): void {
    this.getIsDoorDirectionReversed$()
      .pipe(take(1))
      .subscribe((reverseDoorDirectionValue: boolean) =>
        localStorage.setItem(
          'mock.getswitchposition',
          JSON.stringify(reverseDoorDirectionValue)
        )
      );
  }

  private _mockMovementOfDoor(): void {
    this.setFineTuningModeReady(false);
    setTimeout(() => this.setFineTuningModeReady(true), 3000);
  }
}

export const environment = {
  production: false,
  REPORTING_PATH: 'https://pullreportcustomertest.azurewebsites.net/api/box-reports/add',
  API_URL: 'https://maveoproadmin-test.azurewebsites.net/api/',
  API_KEY: 'Xnd0JiFcbURjdHhTIDQe3MgI',
  API_CLIENT_ID: 'maveoserviceapp',
  enableDebug: true,
  buildTarget: 'web',
  keycloak: {
    issuer: 'https://maveoprokeycloak.germanywestcentral.cloudapp.azure.com/auth/',
    realm: 'marantec',
    clientId: 'mcp-app-test',
  },
};

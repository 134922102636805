import {
  ButtonComponent,
  CardComponent,
  CardCustomTitleComponent,
  InfoButtonComponent,
  ListViewComponent,
  LoaderComponent,
  PressReleaseButtonComponent,
  SingleListItemComponent,
  VerticalSliderComponent,
  RadioItemListComponent,
  SearchFieldComponent,
  FileSelectComponent,
  UploadCloudButtonComponent
} from './components';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GatePositionStatusButtonComponent } from '@shared/components/gate-position-status-button/gate-position-status-button.component';
import { provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StatusIconComponent } from '@shared/components/status-icon/status-icon.component';
import { TranslocoModule } from '@ngneat/transloco';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { MatIconModule } from '@angular/material/icon';
import { StatusSignalComponent } from './components/status-signal/status-signal.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { MatOptionModule } from '@angular/material/core';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LeaveButtonComponent } from '@app/features/main/components';
import { DragnDropDirective } from './directives/dragndrop.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AreYouSureDialogComponent } from '@app/features/main/components/are-you-sure-dialog/are-you-sure-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RemoteControlComponent } from '@app/core/components/remote-control/remote-control.component';
import { MatSliderModule } from '@angular/material/slider';
import { CardButtonBarComponent } from './components/card-button-bar/card-button-bar.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const imports = [
  CommonModule,
  MatIconModule,
  TranslocoModule,
  MatInputModule,
  MatSelectModule,
  FormsModule,
  ReactiveFormsModule,
  MatOptionModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatDialogModule,
  MatSliderModule,
  MatIconModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSlideToggleModule
];
const declarations = [
  ListViewComponent,
  SingleListItemComponent,
  CardComponent,
  CardCustomTitleComponent,
  InfoButtonComponent,
  ButtonComponent,
  LoaderComponent,
  PressReleaseButtonComponent,
  VerticalSliderComponent,
  UploadCloudButtonComponent,
  StatusIconComponent,
  GatePositionStatusButtonComponent,
  RadioItemListComponent,
  CheckboxListComponent,
  BackButtonComponent,
  StatusSignalComponent,
  SearchFieldComponent,
  LanguageSelectorComponent,
  LeaveButtonComponent,
  DragnDropDirective,
  FileSelectComponent,
  AreYouSureDialogComponent,
  RemoteControlComponent,
  CardButtonBarComponent
];
const exports = [...declarations, TranslocoModule];

@NgModule({
  imports: [imports],
  declarations,
  exports,
  providers: [
    provideHttpClient()]
})
export class SharedModule {}

<ul class="list-group">
  <li
    *ngFor="let item of items"
    class="list-group-item"
    [ngClass]="{'highlighted': item.isHighlighted}"
  >
    <div class="d-flex justify-content-between align-items-center">
      <ng-container *ngIf="!item.hasTextInput">
        <button 
        class="list-group-item__label-button"
        [disabled]="item.isDisabled"
        (click)="itemClickHandler(item)"
        >
          <div class="title">{{ item.label | transloco }}</div>
          <div *ngIf="item.hasInfoDialog"><mcp-info-button (buttonClicked)="infoButtonClickHandler()"></mcp-info-button></div>
          <div *ngIf="item.subtitle" class="subtitle" [ngStyle]="{'color': item.subtitleColor ? item.subtitleColor : null}">{{ item.subtitle | transloco }}</div>
          <div *ngIf="item.text" class="text">{{ item.text | transloco }}</div>
          <ul *ngIf="item.tags && item.tags.length > 0" class="tags">
            <li *ngFor="let tag of item.tags" (click)="tagClicked(tag); $event.stopPropagation();">
              #{{tag}}
            </li>
          </ul>
      </button>
      </ng-container>
      

      <ng-container *ngIf="item.hasTextInput">
        <ng-container
          *ngTemplateOutlet="
          textInputTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="item.hasToggleSwitch">
        <ng-container
          *ngTemplateOutlet="
          toggleSwitchTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="item.selectButtons && item.selectButtons.length > 0">
        <ng-container
          *ngTemplateOutlet="
          selectButtonsTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="item.statusSignalType">
        <ng-container
          *ngTemplateOutlet="
          statusSignalTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="item.value && !item.selectOptions && !item.hasTextInput">
        <ng-container
          *ngTemplateOutlet="
          valueTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>
      

      <button
        *ngIf="item.linkType === 'url'"
        class="list-group-item__action-button"
        [disabled]="item.isDisabled"
      >
        <img [src]="'/assets/icons/chevron-right.svg'" />
      </button>
      <ng-container *ngIf="item.linkType === 'action'">
        <ng-container
          *ngTemplateOutlet="
            actionButtonsTemplate;
            context: { action: item.linkAction, item: item }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="item.selectOptions && item.selectOptions.length > 0">
        <ng-container
          *ngTemplateOutlet="
            dropdownTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>
      <ng-container *ngIf="item.sliderParams">
        <ng-container
          *ngTemplateOutlet="
            sliderTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>
    </div>
    <div class="elements-small-screen">
      <ng-container *ngIf="item.selectButtons && item.selectButtons.length > 0">
        <ng-container
          *ngTemplateOutlet="
          selectButtonsTemplate;
            context: { item }
          "
        ></ng-container>
      </ng-container>
    </div>
  </li>
</ul>

<ng-template #statusSignalTemplate let-item="item">
  <mcp-status-signal [title]="item.statusSignalTitle" [type]="item.statusSignalType"></mcp-status-signal>
</ng-template>

<ng-template #valueTemplate let-item="item">
  <span class="value">{{item.value}}</span>
</ng-template>

<ng-template #selectButtonsTemplate let-item="item">
  <button *ngFor="let button of item.selectButtons"
    class="list-group-item__select-button"
    [disabled]="item.isDisabled"
    (click)="selectButtonClickHandler(button)"
  >
    {{ button.label | transloco }}
  </button>
</ng-template>

<ng-template #actionButtonsTemplate let-action="action" let-item="item">
  <button
    class="list-group-item__action-button"
    [ngClass]="{'disabled': item.isDisabled}"
    [disabled]="item.isDisabled"
    *ngIf="action === Action.ADD"
    (click)="actionClickHandler(item)"
  >
    <img [src]="'/assets/icons/add-icon.svg'" />
  </button>
  <button
    class="list-group-item__action-button-with-text"
    [ngClass]="{'disabled': item.isDisabled}"
    [disabled]="item.isDisabled"
    *ngIf="action === Action.EXECUTE"
    (click)="actionClickHandler(item)"
  >
    {{ 'button.execute' | transloco }}
  </button>
  <button
    class="list-group-item__action-button-with-text"
    [ngClass]="{'disabled': item.isDisabled}"
    [disabled]="item.isDisabled"
    *ngIf="action === Action.SET"
    (click)="actionClickHandler(item)"
  >
    {{ 'button.set' | transloco }}
  </button>
  <button
    class="list-group-item__action-button-with-text"
    [ngClass]="{'disabled': item.isDisabled}"
    [disabled]="item.isDisabled"
    *ngIf="action === Action.DISABLE"
    (click)="actionClickHandler(item)"
  >
    {{ 'button.disable' | transloco }}
  </button>
  <button
    class="list-group-item__action-button list-group-item__action-button--remove"
    [ngClass]="{'disabled': item.isDisabled}"
    [disabled]="item.isDisabled"
    *ngIf="action === 'remove'"
    (click)="actionClickHandler(item)"
  >
    <img [src]="'/assets/icons/close-icon.svg'" />
  </button>
  <button
    class="list-group-item__action-button"
    [ngClass]="{'disabled': item.isDisabled}"
    [disabled]="item.isDisabled"
    *ngIf="action === 'info'"
    (click)="actionClickHandler(item)"
  >
    <img [src]="'/assets/icons/info-icon.svg'" />
  </button>
  <button
    class="list-group-item__action-button"
    [ngClass]="{'disabled': item.isDisabled}"
    [disabled]="item.isDisabled"
    *ngIf="action === 'none'"
  >
  </button>
  <button
    class="list-group-item__action-button"
    [disabled]="item.isDisabled"
    *ngIf="!action"
  >
    <img [src]="'/assets/icons/chevron-right.svg'" />
  </button>
</ng-template>

<ng-template #dropdownTemplate let-item="item">
  <mat-form-field class="dropdown {{selectorSize}}">
    <mat-label *ngIf="item.selectorLabel">{{item.selectorLabel | transloco}}</mat-label>
    <mat-select matNativeControl
            [(ngModel)]="item.value"
            (selectionChange)="onSelectChange(item)"
            [disabled]="item.disabled"
          >
      <mat-option *ngFor="let option of item.selectOptions" [value]="option.id">
        {{ option.label | transloco }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #sliderTemplate let-item="item">
  <mat-slider
      class="slider"
      [disabled]="item.isDisabled"
      [max]="item.sliderParams.max"
      [min]="item.sliderParams.min"
      [step]="item.sliderParams?.step || 1"
      >
      <input (valueChange)="onDragEnd(item)" matSliderThumb [(ngModel)]="item.value" #slider>
  </mat-slider>
</ng-template>

<ng-template #textInputTemplate let-item="item">
  <mat-form-field class="full-width">
    <mat-label>{{item.label | transloco}}</mat-label>
    <input (input)="onChangeTextInput(item)" matInput [(ngModel)]="item.value">
  </mat-form-field>
</ng-template>

<ng-template #toggleSwitchTemplate let-item="item">
    <mat-slide-toggle
          class="switch-toggle"
          [(ngModel)]="item.isChecked"
          [checked]="item.isChecked"
          (change)="onSlideToggleChange()"
          [disabled]="item.disabled">
      </mat-slide-toggle>
</ng-template>

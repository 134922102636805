<div class="searchfield">
  <input
    matInput
    placeholder="{{ 'search.placeholderDefault' | transloco }}"
    type="search"
    [value]="text()"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="none"
    [formControl]="searchTerm"
    (input)="search($event)"
  />
  <div class="searchfield-description">{{ description | transloco }}</div>
</div>